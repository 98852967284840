import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../project.service'
import { CompanyService } from '../company.service'
import { Project } from '../project';
import { Company } from '../company';
import { Router } from '@angular/router'


@Component({
    selector: 'app-project-edit',
    templateUrl: './project-edit.component.html',
    styleUrls: ['./project-edit.component.scss']
})
export class ProjectEditComponent implements OnInit {

    public project: Project;
    public projectsbycompany: Company[];
    public companies: Company[];
    public projectId: number;
    public subscription: any;

    constructor(
        private projectService: ProjectService,
        private companyService: CompanyService,
        private route: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {
        this.subscription = this.route.params.subscribe(params => {
            console.log(params);
            this.projectId = +params['projectId']; // (+) converts string 'id' to a number
            if(this.projectId > 0){
                this.projectService.getSingle(this.projectId).then((data) => {
                    this.project = data;
                    console.log(this.project);
                });
            }
            else {
                this.project = new Project();
            }


        });

        this.companyService.getHavingRegistrations().then((companies) => {
            this.companies = companies;
        });
        this.projectService.getCompanieswithprojects().then((projects) => {
            this.projectsbycompany = projects;
            console.log(projects, this.projectsbycompany);
        });

    }

    private save() {
        console.log(this.project);
        this.projectService.save(this.project).then((data) => {
            console.log(data);
            if(data['data'] != true && isFinite(+data['data'])){
                this.router.navigate(['/project-edit', data['data']]);
            }
        });
    }


}
