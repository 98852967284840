import {Injectable} from '@angular/core';
import {Company} from './company';
import {ApiService} from './api.service';

import 'rxjs/add/operator/toPromise';
import {Azureteam} from './azureteam';

@Injectable()
export class CompanyService {

    constructor(private apiService: ApiService) {
    }


    public getAll(): Promise<Company[]> {
        return this.apiService.getCall<Company[]>('companies/all', null);
    }

    save(company: Company) {
        return this.apiService.postCall('companies', company);
    }

    public getSingle(companyId: number): Promise<Company> {
        return this.apiService.getCall<Company>('companies/single', {companyId: companyId});
    }

    public update(company: Company) {
        return this.apiService.postCall('companies/update', company);
    }

    public getAzureTeams(): Promise<{ value: Azureteam[] }> {
        return this.apiService.getCall<{ value: Azureteam[] }>('companies/azureteams');
    }

    public getAzureItems(companyId: number): Promise<any> {
        return this.apiService.getCall<any>('companies/azureitems', {companyId});
    }

    public getHavingRegistrations(): Promise<Company[]> {
        return this.apiService.getCall<Company[]>('companies/havingregistrations', null);
    }
}
