import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Subscription} from './subscription';
import {Company} from './company';

@Injectable()
export class SubscriptionsService {

    constructor(private apiService: ApiService) {
    }

    public getSubscriptions() {
        return this.apiService.getCall$<Subscription[]>('subscriptions');
    }

    public getSubscription(id: number) {
        return this.apiService.getCall$<Subscription>('subscriptions/' + id);
    }
    save(subscription: Subscription) {
        return this.apiService.postCall('subscriptions', subscription);
    }


}
