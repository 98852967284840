<h1>Companies</h1>

<input type="text" [(ngModel)]="filter" (ngModelChange)="doFilter()" placeholder="Search a company" />

<table class="table table-striped">
  <thead>
    <tr>
      <td>Name</td>
      <td>Email</td>
      <td>Exact</td>
    </tr>
  </thead>
  <tr *ngFor="let company of filteredCompanies" (click)="edit(company.company_id)" class="curpoint">
    <td>{{company.name}}</td>
    <td>{{company.email}}</td>
    <td>{{company.exact_account_id}}</td>
  </tr>
</table>
