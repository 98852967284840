import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'byId'
})
export class ByIdPipe implements PipeTransform {

    transform<T>(value: T[], id: number | string): T {
        if (value) {
            return value.find(p => p['id'] === id);
        }
        return null;
    }

}
