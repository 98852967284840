import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Registrion} from './registration';

import 'rxjs/add/operator/toPromise';
import {formatDate} from '@angular/common';

@Injectable()
export class RegistrationService {


    constructor(private apiService: ApiService) { }

    public getAll(selectedDate: Date): Promise<Registrion[]> {
        return this.apiService.getCall<Registrion[]>('registration/all', { date: selectedDate });
    }

    public save(selectedDate: Date, registrations: Registrion[], comments: any): Promise<Registrion[]> {
        console.log(JSON.stringify(comments));
        return this.apiService.postCall<Registrion[]>('registration/save',
            {
                date : selectedDate,
                registrations: registrations.map(r => {
                    r.from = formatDate(r.from, 'yyyy-MM-dd HH:mm', 'nl') as any;
                    r.till = formatDate(r.till, 'yyyy-MM-dd HH:mm', 'nl') as any;
                    return r;
                }),
                comments
            });
    }

}
