import { Injectable } from '@angular/core';
import { Settings } from './settings';
import { ApiService } from './api.service';
import {User} from './user';


@Injectable()
export class AuthService {

  constructor(private apiService : ApiService) { }

    getUserToken(userInfo: Object): Promise<Object> {
        return this.apiService.postCall('auth/login', userInfo);

    }

    getUserInfo(): Promise<User> {
        return this.apiService.getCall<User>('oauth/user', {});
    }

    logout(): Promise<Object> {
        return this.apiService.postCall('auth/logout', {});
    }


}
