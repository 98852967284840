<h3>Edit company {{company?.name}}</h3>

<ng-container *ngIf="form">
    <ng-select [items]="accounts"
               bindLabel="name"
               bindValue="id"
               [formControl]="form.controls['exact_account_id']">

    </ng-select>
    <button (click)="save()">Opslaan</button>
</ng-container>
<pre>
{{company | json}}
  </pre>

<h4>Azure intergration</h4>
<ng-container *ngIf="company && company.azure_desc">
    This company is currently connected to {{company.azure_desc}}.
    Use "Load Azure teams" to connect to another team.
</ng-container>
<br><br>
<button (click)="loadAzureItems()">Load Azure items</button>
<pre>{{azureItems | json}}</pre>
<br><br>

<button (click)="loadAzureTeams()">Load Azure teams</button>
<ng-container *ngIf="azureTeams">
    <h4>Choose an Azure team:</h4>
    <button (click)="connectAzure(team)" *ngFor="let team of azureTeams">
        {{team.name}} / {{team.projectName}}
    </button>
</ng-container>
<br><br>
