<h1>Financials</h1>
<label><input type="checkbox" [(ngModel)]="showSubs"> Toon abo's</label>
<table class="table table-striped" style="width: 1050px;">
    <thead class="txtbold">
    <tr class="txtright">
        <td></td>
        <td></td>
        <td colspan="2">Previous month</td>
        <td colspan="2">Current month</td>
    </tr>
    <tr>
        <td>Company</td>
        <td>Invoices</td>
        <td class="txtright" width="90">Hours</td>
        <td class="txtright" width="100">Revenue</td>
        <td class="txtright" width="90">Hours</td>
        <td class="txtright" width="100">Revenue</td>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let company of hourlyCompanies">
        <tr *ngIf="company.prevmonth || company.curmonth || showSubs">
            <td>
                <div style="float: left">
                    {{company.name}}<br>
                    {{utils.decimalPrice(company.hourprod.price)}} / uur
                </div>
                <button class="flright btn btn-primary"
                        [disabled]="invoicing"
                        (click)="invoiceMonth(company.company_id)"
                        *ngIf="hasInvoice.indexOf(company.company_id) === -1">
                    {{invoicing === company.company_id ? 'Factuur aanmaken...' : 'Factureer'}}
                </button>
            </td>
            <td>
                <ng-container *ngFor="let invoice of invoices">
                    <ng-container *ngIf="invoice.invoice_to === company.exact_account_id">
                        <div title="{{invoice.description}}"
                             [class.txtgreen]="invoice.status === 'Betaald'"
                             [class.txtred]="invoice.status === 'Achterstallig'">
                            <div style="float: left; width: 100px;">
                                {{invoice.status}}
                            </div>
                            <div style="float: left; width: 70px;">
                                {{invoice.date  | date : 'dd MMM'}}
                            </div>
                            <div style="float: left; width: 50px;">
                                {{invoice.number}}
                            </div>
                            <div class="text-right">
                                {{invoice.amount | currency : 'EUR' : 'symbol' : '0.0-0'}}
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </td>
            <td class="txtright">{{company.prevmonth ? utils.decimalTime(company.prevmonth) : ''}}</td>
            <td class="txtright">{{company.prevmonth * +company.hourprod?.price | currency : 'EUR' : 'symbol' : '0.0-0'}}</td>
            <td class="txtright">{{company.curmonth ? utils.decimalTime(company.curmonth) : ''}}</td>
            <td class="txtright">{{company.curmonth * +company.hourprod?.price  | currency : 'EUR' : 'symbol' : '0.0-0'}}</td>
        </tr>
    </ng-container>
    <tr class="txtbold">
        <td colspan="2">Totals</td>
        <td class="txtright">{{utils.decimalTime(utils.sumProps(hourlyCompanies, 'prevmonth'))}}</td>
        <td class="txtright">&euro; {{utils.sumProps(hourlyCompanies, 'prevmonth', calq) | number:'1.2-2'}}</td>
        <td class="txtright">{{utils.decimalTime(utils.sumProps(hourlyCompanies, 'curmonth'))}}</td>
        <td class="txtright">&euro; {{utils.sumProps(hourlyCompanies, 'curmonth', calq) | number:'1.2-2'}}</td>
    </tr>
    <tr class="spacer">
        <td colspan="6"><br></td>
    </tr>
    <tr class="txtbold">
        <td colspan="2">Employee</td>
        <td class="txtright">Hours</td>
        <td class="txtright">Costs</td>
        <td class="txtright">Hours</td>
        <td class="txtright">Costs</td>
    </tr>
    <ng-container *ngFor="let employee of hourlyEmployees">
        <tr *ngIf="employee.prevmonth || employee.curmonth">
            <td colspan="2">{{employee.name}}</td>
            <td class="txtright">{{employee.prevmonth ? utils.decimalTime(employee.prevmonth) : ''}}</td>
            <td class="txtright">{{utils.decimalPrice(employee.prevmonth * employee.hourly_rate)}}</td>
            <td class="txtright">{{employee.curmonth ? utils.decimalTime(employee.curmonth) : ''}}</td>
            <td class="txtright">&euro; {{employee.curmonth * employee.hourly_rate | number:'1.2-2'}}</td>
        </tr>
    </ng-container>
    <tr>
        <td colspan="2">General costs</td>
        <td class="txtright"></td>
        <td class="txtright">{{utils.decimalPrice(generalCosts)}}</td>
        <td class="txtright"></td>
        <td class="txtright">{{utils.decimalPrice(generalCosts)}}</td>
    </tr>
    <tr class="txtbold">
        <td colspan="2">Total</td>
        <td class="txtright"></td>
        <td class="txtright">{{
                utils.decimalPrice(
                    utils.sumProps(hourlyCompanies, 'prevmonth', calq) -
                    utils.sumProps(hourlyEmployees, 'prevmonth', calqemployee) -
                    generalCosts
                )
            }}
        </td>
        <td class="txtright"></td>
        <td class="txtright">{{
                utils.decimalPrice(
                    utils.sumProps(hourlyCompanies, 'curmonth', calq) -
                    utils.sumProps(hourlyEmployees, 'curmonth', calqemployee) -
                    generalCosts
                )
            }}
        </td>
    </tr>
    <tr class="txtbold">
        <td colspan="2">Total 0.5</td>
        <td class="txtright"></td>
        <td class="txtright">{{
                utils.decimalPrice(
                    (utils.sumProps(hourlyCompanies, 'prevmonth', calq) -
                        utils.sumProps(hourlyEmployees, 'prevmonth', calqemployee) -
                        generalCosts) / 2
                )
            }}
        </td>
        <td class="txtright"></td>
        <td class="txtright">{{
                utils.decimalPrice(
                    (utils.sumProps(hourlyCompanies, 'curmonth', calq) -
                        utils.sumProps(hourlyEmployees, 'curmonth', calqemployee) -
                        generalCosts) / 2
                )
            }}
        </td>
    </tr>
    </tbody>
</table>
<div class="row">
    <div *ngFor="let year of yearproducts" class="col-xs-4">
        <h1>{{year.year}}</h1>
        <table class="table table-striped">
            <thead class="txtbold">
            <tr class="txtright">
                <td class="text-left" width="100">Product</td>
                <td>&euro;</td>
                <td>Count</td>
                <td>Revenue</td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let product of year.products">
                <td title="{{product.name}}">
                    <div style="width: 140px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap">
                        {{product.name}}
                    </div>
                </td>
                <td class="txtright">{{product.price | currency:'€'}}</td>
                <td class="txtright">{{product.totalcount | number:'0.0-0'}}</td>
                <td class="txtright">{{+product.price * +product.totalcount | currency:'€':'€':'0.0-0'}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
