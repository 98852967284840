import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {User} from './user';

import 'rxjs/add/operator/toPromise';

@Injectable()
export class UserService {


  constructor(private apiService : ApiService) { }

  public getAll() : Promise<User[]> {
    return this.apiService.getCall<User[]>('users/all', {});
  }

}
