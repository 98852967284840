import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {MomentModule} from 'angular2-moment';

import {AppComponent} from './app.component';
import {RegistrationComponent} from './registration/registration.component';
import {CompanyComponent} from './company/company.component';
import {CompanyEditComponent} from './company-edit/company-edit.component';

import {ApiService} from './api.service';
import {CompanyService} from './company.service';
import {RegistrationService} from './registration.service';
import {UserService} from './user.service';
import {ProjectService} from './project.service';
import {ReportService} from './report.service';
import {LoginComponent} from './login/login.component';
import {ReportComponent} from './report/report.component';
import {AuthService} from './auth.service';
import {HomeComponent} from './home/home.component';
import {LogoutComponent} from './logout/logout.component';
import {IsdDatepickerComponent} from './my-datepicker.component';

import {ProjectComponent} from './project/project.component';
import {ProjectEditComponent} from './project-edit/project-edit.component';
import {FinancialComponent} from './financial/financial.component';
import {FinancialService} from './financial.service';
import {HttpClientModule} from '@angular/common/http';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {ModalModule} from 'ngx-bootstrap/modal';
import {registerLocaleData} from "@angular/common";
import localeNL from '@angular/common/locales/nl';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgSelectModule} from '@ng-select/ng-select';
import {ExactService} from './exact.service';
import {SubscriptionsComponent} from './subscriptions/subscriptions.component';
import {SubscriptionsService} from './subscriptions.service';
import {SubscriptionEditComponent} from './subscription-edit/subscription-edit.component';
import {ByIdPipe} from './pipes/by-id.pipe';

registerLocaleData(localeNL, 'nl');

const appRoutes: Routes = [
    {path: 'financials', component: FinancialComponent},
    {path: 'subscriptions/:id', component: SubscriptionEditComponent},
    {path: 'subscriptions', component: SubscriptionsComponent},
    {path: 'registration', component: RegistrationComponent},
    {path: 'companies', component: CompanyComponent},
    {path: 'companies/:companyId', component: CompanyEditComponent},
    {path: 'reports/:userId/:companyId', component: ReportComponent},
    {path: 'reports/:userId', component: ReportComponent},
    {path: 'reports', component: ReportComponent},
    {path: 'project-edit/:projectId', component: ProjectEditComponent},
    {path: 'projects', component: ProjectComponent},
    {path: 'login', component: LoginComponent},
    {path: 'logout', component: LogoutComponent},
    {path: '', component: HomeComponent},
];


@NgModule({
    declarations: [
        AppComponent,
        RegistrationComponent,
        CompanyComponent,
        CompanyEditComponent,
        LoginComponent,
        ReportComponent,
        HomeComponent,
        SubscriptionsComponent,
        SubscriptionEditComponent,
        LogoutComponent,
        IsdDatepickerComponent,
        ProjectComponent,
        ProjectEditComponent,
        FinancialComponent,
        ByIdPipe
    ],
    imports: [
        NgSelectModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes),
        BrowserModule,
        FormsModule,
        HttpClientModule,
        MomentModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        BsDatepickerModule.forRoot()
    ],
    providers: [
        ApiService,
        CompanyService,
        RegistrationService,
        AuthService,
        ProjectService,
        ExactService,
        UserService,
        SubscriptionsService,
        ByIdPipe,
        ReportService,
        FinancialService,
        {provide: LOCALE_ID, useValue: 'nl'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

