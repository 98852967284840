import {Component, OnInit} from '@angular/core';
import {Utils} from '../utils.class';
import {SubscriptionsService} from '../subscriptions.service';
import {Subscription} from '../subscription';
import {ActivatedRoute} from '@angular/router';
import {ExactService} from '../exact.service';
import {ExactItem} from '../item';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-subscription-edit',
    templateUrl: './subscription-edit.component.html',
    styleUrls: ['./subscription-edit.component.scss']
})
export class SubscriptionEditComponent implements OnInit {

    subscription: Subscription;

    utils = Utils;
    items: ExactItem[];
    form: FormGroup;

    public generalCosts = 750;

    constructor(private route: ActivatedRoute,
                private exactService: ExactService,
                private subscriptionsService: SubscriptionsService) {
    }


    ngOnInit() {

        this.route.params.subscribe(params => {
            this.reload(+params['id']);
        });

        this.exactService.getItems().subscribe(acc => {
            console.log(acc);
            this.items = acc.data;
        });
    }

    reload(id: number) {
        this.subscriptionsService.getSubscription(id).subscribe((response) => {
            this.subscription = response.data;

            this.form = new FormGroup({
                id: new FormControl(this.subscription.id),
                exact_item_id: new FormControl(this.subscription.exact_item_id),
                description: new FormControl(this.subscription.description),
                comment: new FormControl(this.subscription.comment),
                count_url: new FormControl(this.subscription.count_url),
                count: new FormControl(this.subscription.count),
            });
        });

    }


    save() {
        this.subscriptionsService.save(this.form.value);
    }

}
