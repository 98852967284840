import * as moment from 'moment';

export class Utils {

    public static duration(starttime: Date, endtime: Date): number {
        console.log(starttime, endtime);
        return moment(starttime).diff(moment(endtime), 'minutes');
    }

    public static datechanged(starttime: Date, endtime: Date) {
        return !moment(starttime).isSame(moment(endtime), 'd');
    }

    public static timechanged(starttime: Date, endtime: Date) {
        return moment(starttime).diff(moment(endtime), 'minutes') != 0;
    }

    public static generateArray(obj) {
        if (!obj) {
            return [];
        }
        return Object.keys(obj).map((key) => {
            return {key: key, value: obj[key]}
        });
    }


    public static projectName(project) {
        if (!project) {
            return " other projects";
        }
        let string = project.company_name;
        if (project.parent_parent_name) {
            string += " &raquo; " + project.parent_parent_name;
        }
        if (project.parent_name) {
            string += " &raquo; " + project.parent_name;
        }
        string += " &raquo; " + project.name;

        return string;
    }

    public static decimalTime(decimal: any) {
        let dec = Math.abs(Math.round((decimal % 1) * 100) / 100 * 60) + "";
        if (parseInt(dec) <= 9) {
            dec = "0" + dec;
        }
        if (decimal > 0) {
            return Math.floor(decimal) + ":" + dec;
        }
        else {
            if (Math.ceil(decimal) == 0) {
                return '-' + Math.ceil(decimal) + ":" + dec;
            }
            return Math.ceil(decimal) + ":" + dec;
        }

    }

    public static decimalPrice(decimal: any) {
        let dadecimal = +decimal;
        if (!dadecimal)
            return '';
        console.log(dadecimal);
        return '€ ' + dadecimal.toFixed(2).replace('.', ',');
    }

    public static sumProps(list, property, dafunction = null) {
        let total = 0;
        if (list && list.length > 0) {
            list.forEach((item) => {
                if (dafunction && typeof dafunction == 'function')
                    total += +dafunction(item, property);
                else
                    total += +item[property];
            })
        }
        return total;
    }

    public static isLocalHost() {
        return (location.hostname === "localhost" || location.hostname === "crm17.localhost");
    }
}
