import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

import 'rxjs/add/operator/toPromise';
import {ExactAccount} from './account';
import {ExactItem} from './item';
import {ExactSalesInvoice} from './exactsalesinvoice';

@Injectable()
export class ExactService {

    constructor(private apiService: ApiService) {
    }


    public getAccounts() {
        return this.apiService.getCall$<ExactAccount[]>('exact/accounts');
    }

    public invoiceMonth(companyId: number) {
        return this.apiService.postCall<ExactAccount[]>('exact/invoice/' + companyId);
    }

    public getItems() {
        return this.apiService.getCall$<ExactItem[]>('exact/items');
    }

    public getInvoices(updateCache: boolean) {
        return this.apiService.getCall$<ExactSalesInvoice[]>('exact/invoices', {updateCache});
    }

}
