import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service'
import { Router } from '@angular/router'


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    constructor(
                private authService: AuthService,
                private router: Router
    ) { }


  ngOnInit() {
    this.authService.getUserInfo().then().then(response => {
          this.router.navigate(['registration']);
      }, error => {
          this.router.navigate(['login']);
      });
  }

}
