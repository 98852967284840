import {Component} from '@angular/core';
import {Utils} from './utils.class';
import {User} from './user';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';
import {setTheme} from 'ngx-bootstrap/utils';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    utils: Utils = Utils;
    title = 'app works!';
    public authenticatedUser: User;

    constructor(private authService: AuthService, private router: Router) {
        setTheme('bs3');
        this.authService.getUserInfo().then(user => {
            this.authenticatedUser = user;
            if (this.router.url == "/login") {
                console.log("Got 200, redirect to registration");
                this.router.navigate(['registration']);
            }
        });
    }

    loadUserInfo() {
        this.authService.getUserInfo().then(user => {
            this.authenticatedUser = user;
        });
    }
}
