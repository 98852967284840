import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Project} from './project';
import {Company} from './company';

import 'rxjs/add/operator/toPromise';

@Injectable()
export class ProjectService {


    constructor(private apiService: ApiService) {
    }

    public getAll(): Promise<Project[]> {
        return this.apiService.getCall<Project[]>('project/all', null);
    }

    public getCompanieswithprojects(): Promise<Company[]> {
        return this.apiService.getCall<Company[]>('project/companieswithprojects', null);
    }


    public getSingle(projectId: number): Promise<Project> {
        return this.apiService.getCall<Project>('project/get', {projectId: projectId});
    }

    public getVisible(date: Date): Promise<Project[]> {
        return this.apiService.getCall<Project[]>('project/visible', {date: date});
    }

    public azure(): Promise<boolean> {
        return this.apiService.getCall<boolean>('project/azure');
    }

    public toggleVisible(project: Project, add: boolean): Promise<boolean> {
        return this.apiService.postCall('project/togglevisible', {projectId: project['workproject_id'], add});
    }

    public findHiddenProject(searchText: string, date: Date): Promise<Project[]> {
        return this.apiService.getCall<Project[]>('project/findhidden', {searchText: searchText, date: date});
    }

    public save(project: Project): Promise<boolean> {
        return this.apiService.postCall<boolean>('project/save', {
            project: {
                budget_fixed: project.budget_fixed || false,
                company_id: project.company_id,
                name: project.name,
                parent_workproject_id: project.parent_workproject_id,
                workproject_id: project.workproject_id,
                budget: project.budget || null
            }
        });
    }

}
