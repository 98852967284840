import {Component, forwardRef, Inject, OnInit} from '@angular/core';
import {AuthService} from '../auth.service'
import {Router} from '@angular/router'
import {AppComponent} from "../app.component";

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

    constructor(
        private authService: AuthService,
        private router: Router,
        @Inject(forwardRef(() => AppComponent)) public app: AppComponent
    ) {
    }


    ngOnInit() {
        this.authService.logout().then().then(response => {
            this.app.authenticatedUser = null;
            this.router.navigate(['login']);
        }, error => {
            console.log("fail");
            this.router.navigate(['login']);
        });
    }
}
