<h1>Urenspecificatie

    <span *ngIf="userId || companyId"> for </span>
    <span *ngIf="userId && userById"> {{userById[userId]}} </span>
    <span *ngIf="companyId"> {{getCompany(companyId).name}} </span>
</h1>
<style>
    .light {
        color: #999;
    }

    h1 {
        font-weight: bold;
        font-size: 18px;
    }

    h2 {
        font-size: 15px;
        font-weight: bold;
        margin-top: 0;
    }

    .timedetails span:not(.light):not(.comment) {
        font-weight: bold;
    }
</style>

<div class="hideprint">
    Periode:
    <select name="month" [(ngModel)]="selectedMonth" (change)="loadRegistrations()">
        <option value="">Alle maanden</option>
        <option value="1">Januari</option>
        <option value="2">Februari</option>
        <option value="3">Maart</option>
        <option value="4">April</option>
        <option value="5">Mei</option>
        <option value="6">Juni</option>
        <option value="7">Juli</option>
        <option value="8">Augustus</option>
        <option value="9">September</option>
        <option value="10">Oktober</option>
        <option value="11">November</option>
        <option value="12">December</option>
    </select>
    <select name="month" [(ngModel)]="selectedYear" (change)="loadRegistrations()">
        <option value="">Alle jaren</option>
        <option value="{{year}}" *ngFor="let year of years">{{year}}</option>
    </select>
    <br><br>
</div>
<div class="row" [hidden]="companyId || userId">
    <div class="col-sm-4" [hidden]="!app.authenticatedUser || app.authenticatedUser.is_admin!=true">
        <h2>Choose a company</h2>
        <ng-container *ngFor="let company of companies">
            <a [routerLink]="['/reports', '', company.company_id]">
                {{company.name}}
            </a><br>
        </ng-container>
    </div>
    <div class="col-sm-4">
        <h2>Choose a user</h2>
        <ng-container *ngFor="let user of users">
            <div
                [hidden]="!app.authenticatedUser || ( app.authenticatedUser.is_admin!=1 && app.authenticatedUser.user_id != user.user_id)">
                <a [routerLink]="['/reports', user.user_id, '']">
                    {{user.name}}
                </a><br>
            </div>
        </ng-container>
    </div>
</div>
<br>

<span *ngIf="companyId && registrations?.length == 0">
    There is no work registered for the company {{getCompany(companyId).name}}
    in this period.
    <br><br>
</span>
<span *ngIf=" userId && registrations?.length == 0">
    The user {{userById[userId]}} did not register work during this period.
    <br><br>
</span>

<span class="curpoint hideprint" (click)="registrations=[];totals=[];assignments=[];companyId=null;userId=null;"
      [hidden]="!companyId && !userId">&laquo; Other report<br><br></span>
<table class="table table-striped" style="width: 800px; " *ngIf="totals && totals.length > 0">
    <thead>
    <tr class="txtbold">
        <td>Project</td>
        <td class="txtright" width="150">Budget</td>
        <td class="txtright">Periode</td>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let parent of totals">
        <tr *ngFor="let total of parent.childs">
            <td [innerHTML]="utils.projectName(projectById[total.workproject_id])" *ngIf="projectById"></td>
            <td class="txtright" [class.txtred]="total.budget-total.total < 0">
                {{utils.decimalTime(total.total)}} / {{total.budget ? utils.decimalTime(total.budget) : ''}}
            </td>
            <td class="txtright">{{utils.decimalTime(total.totalPeriod)}}</td>
        </tr>
        <tr class="txtbold">
            <td [innerHTML]="'Subtotaal ' + utils.projectName(projectById[parent.workproject_id])"></td>
            <td class="txtright"
                [class.txtred]="utils.sumProps(parent.childs, 'budget')-utils.sumProps(parent.childs, 'total') < 0">
                {{utils.decimalTime(utils.sumProps(parent.childs, 'total'))}}
                / {{utils.decimalTime(utils.sumProps(parent.childs, 'budget'))}}
            </td>
            <td class="txtright">{{utils.decimalTime(utils.sumProps(parent.childs, 'totalPeriod'))}}</td>
        </tr>
        <tr>
            <td colspan="3">&nbsp;</td>
        </tr>
    </ng-container>
    <tr class="txtbold">
        <td>Totaal budget</td>
        <td class="txtright">
            {{utils.decimalTime(utils.sumProps(totalsbudget, 'budget') - utils.sumProps(totalsbudget, 'total'))}}
            / {{utils.decimalTime(utils.sumProps(totalsbudget, 'budget'))}}
        </td>
        <td class="txtright">{{utils.decimalTime(utils.sumProps(totalsbudget, 'totalPeriod'))}}</td>
    </tr>
    <tr>
        <td colspan="4">&nbsp;</td>
    </tr>
    <tr class="txtbold">
        <td>Totaal</td>
        <td class="txtright">{{utils.decimalTime(utils.sumProps(plaintotals, 'total'))}}
            / {{utils.decimalTime(utils.sumProps(plaintotals, 'budget'))}}
        </td>
        <td class="txtright">{{utils.decimalTime(utils.sumProps(plaintotals, 'totalPeriod'))}}</td>
    </tr>
    </tbody>
</table>

<div class="breakprint"></div>

<ng-container *ngFor="let date of generateArray(registrations); let i = index;">
    <div class="row">
        <h1>{{date.key | amDateFormat:'dddd DD MMMM YYYY'}}</h1>
        <ng-container *ngFor="let user of generateArray(date.value); let i2 = index;">
            <div class="col-xs-6 timedetails">
                <h2>{{userById[user.key]}}</h2>
                <ng-container *ngFor="let registration of user.value; let i3 = index;">
                    <span
                        [ngClass]="{'light' : i3 != 0 && !utils.timechanged(user.value[i3-1].till, registration.from)}">
                        {{registration.from | amDateFormat:"HH:mm"}}
                    </span>
                    -
                    <span
                        [ngClass]="{'light' : i3 != (user.value.length-1) && !utils.timechanged(registration.till, user.value[i3+1].from)}">
                        {{registration.till | amDateFormat:"HH:mm"}}
                    </span>
                    <span class="comment"
                          [innerHTML]="utils.projectName(projectById[registration.workproject_id])"></span>

                    <div style="margin-left: 5px; line-height: 1.6; font-size: 13px;"
                         *ngFor="let comment of registration.comments">
                        {{comment.datetime | amDateFormat:"HH:mm"}} {{comment.description}}
                    </div>
                    <br>
                </ng-container>
            </div>
        </ng-container>
    </div>
</ng-container>
<br>
<ng-container *ngIf="assignments && assignments.length > 0">
    <h1>Assignment report</h1>
    <table class="table table-striped txtright">
        <tr class="txtbold">
            <td>Week</td>
            <td width="95">Sunday</td>
            <td width="95">Monday</td>
            <td width="95">Thuesday</td>
            <td width="95">Wednesday</td>
            <td width="95">Thursday</td>
            <td width="95">Friday</td>
            <td width="95">Saturday</td>
            <td>Total</td>
            <td>Assignment</td>
            <td>Balance</td>
        </tr>
        <tr *ngFor="let assignment of assignments; let i = index;">
            <td>{{assignment.year}} - {{assignment.week}}</td>
            <td>{{assignment.days[1]?.totaltime}}</td>
            <td>{{assignment.days[2]?.totaltime}}</td>
            <td>{{assignment.days[3]?.totaltime}}</td>
            <td>{{assignment.days[4]?.totaltime}}</td>
            <td>{{assignment.days[5]?.totaltime}}</td>
            <td>{{assignment.days[6]?.totaltime}}</td>
            <td>{{assignment.days[7]?.totaltime}}</td>
            <td>{{assignment.totaltime}}</td>
            <td>{{assignment.assignment}}</td>
            <td>{{utils.decimalTime(assignment.totaltime_decimal - assignment.assignment_decimal)}}</td>
        </tr>
        <tr class="txtbold">
            <td colspan="10">Totaal</td>
            <td>{{utils.decimalTime(utils.sumProps(assignments, 'totaltime_decimal') - utils.sumProps(assignments, 'assignment_decimal'))}}</td>
        </tr>
    </table>
</ng-container>

