import {Component, Input, Output, EventEmitter} from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'my-datepicker',
    template: `
        <label>{{label}}</label>
        <input [value]="dateModel | amDateFormat:'DD-MM-YYYY'" (focus)="showPopup()"/>
        <input type="hidden" [(ngModel)]="dateModel" class="form-control"/>
        <bs-datepicker-inline *ngIf="showDatepicker" class="popup" (bsValueChange)="hidePopup($event)" [bsValue]="dateModel"></bs-datepicker-inline>
    `,
    styles: [`
    .popup {
      position: absolute;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #ddd;
      height: 251px;
      bottom: 40px;
    }
    input {
        width: 100px;
        border-radius: 4px;
        margin-left: 0;
        left: 0;
        background-color: #FFF;
        border-radius: 4px;
        border: 1px solid #8c8c8c;
        padding: 6px 10px;
        outline: 0;
    }
  `],
})
export class IsdDatepickerComponent {
    @Input()
    dateModel: Date;
    @Input()
    label: string;
    @Output()
    dateModelChange: EventEmitter<string> = new EventEmitter();
    public origDate : Date;
    public showDatepicker: boolean = false;

    showPopup() {
        console.log("HSOW POPU");
        this.origDate = moment(this.dateModel).toDate();
        this.showDatepicker = true;

    }

    hidePopup(event) {
        console.log("HDIE", this.origDate, event);
        if(!moment(this.origDate).isSame(event, 'date')){
            this.showDatepicker = false;
            this.dateModel = event;
            this.dateModelChange.emit(event)
        }
    }
}
