import {Component, OnInit} from '@angular/core';
import {Utils} from '../utils.class';
import {SubscriptionsService} from '../subscriptions.service';
import {Subscription} from '../subscription';
import {ExactService} from '../exact.service';
import {ExactItem} from '../item';

@Component({
    selector: 'app-subscriptions',
    templateUrl: './subscriptions.component.html',
    styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {

    subscriptions: Subscription[];

    utils = Utils;
    items: ExactItem[];

    constructor(private subscriptionsService: SubscriptionsService,
                private exactService: ExactService) {
    }


    ngOnInit() {

        this.exactService.getItems().subscribe(acc => {
            this.items = acc.data;
        });
        this.subscriptionsService.getSubscriptions().subscribe((subscriptions) => {
            this.subscriptions = subscriptions.data;
        });

    }
}
