
<form (ngSubmit)="login()" #loginForm="ngForm" class="loginform">
        <h1>Inloggen CRM17</h1>
        <label>
                <span>E-mailadres:</span> 
                <input [(ngModel)]="userCredentials.email" name="username" type="email" />
        </label>
        <label>
                <span>Wachtwoord:</span> 
                <input [(ngModel)]="userCredentials.password" name="password" type="password" />
        </label>
        <div class="message">{{message}}</div>

        <a href="#" class="lost">Wachtwoord vergeten?</a>
        <button type="submit" class="button-block">Inloggen</button>
        <div class="clearfix"></div>
</form>
      