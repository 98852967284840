<div class="registration-area">
    <div class="projects">
        <div class="project"></div>
        <ng-container *ngFor="let project of projects">
            <div
                    (click)="removeVisible(project)"
                    *ngIf="(project.children || 0) == 0"
                    [class.hovered]="hoveredProject == project.workproject_id"
                    class="project"
                    title="{{project.company_name}} &raquo; {{project.name}}">
                {{project.company_short ? project.company_short : project.company_name}}
                &raquo; {{project.name}}
                <div class="totaltime">{{totalTime(project.workproject_id)}}</div>
            </div>
            <ng-container *ngFor="let childProject of project.children">
                <div
                        (click)="removeVisible(childProject)"
                        *ngIf="(childProject.children || 0) == 0"
                        [class.hovered]="hoveredProject == childProject.workproject_id"
                        class="project"
                        title="{{project.company_name}} &raquo; {{project.name}} &raquo; {{childProject.name}}">
                    {{project.company_short ? project.company_short : project.company_name}}
                    &raquo; {{project.name}}
                    &raquo; {{childProject.name}}
                    <div class="totaltime">{{totalTime(childProject.workproject_id)}}</div>
                </div>
                <ng-container *ngFor="let subchildProject of childProject.children">
                    <div
                            (click)="removeVisible(subchildProject)"
                            [class.hovered]="subchildProject == project.workproject_id"
                            class="project"
                            title="{{project.company_name}} &raquo; {{project.name}} &raquo; {{childProject.name}} &raquo; {{subchildProject.name}}">
                        {{project.company_short ? project.company_short : project.company_name}}
                        &raquo; {{project.name}}
                        &raquo; {{childProject.name}}
                        &raquo; {{subchildProject.name}}
                        <div class="totaltime">{{totalTime(subchildProject.workproject_id)}}</div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
    <div #registrationhours
         (mousedown)="lg($event)"
         (mousemove)="lg($event)"
         (mouseup)="lg($event)"
         class="hours"
         id="registrationhours">
        <div [ngStyle]="{'left' : (currentTime | amDifference: startTime : 'minutes') * 1.266666666 + 'px'}" class="current-time"></div>
        <div class="project">
            <div *ngFor="let hour of hours"
                 class="hour"
                 title="{{hour | amDateFormat:'HH:mm'}}">
                {{hour | amDateFormat:'HH:mm'}}
            </div>
        </div>
        <ng-container *ngFor="let project of projects">
            <div (mouseenter)="projectHover(project.workproject_id)" (mouseleave)="projectLeave()" *ngIf="(project.children || 0) == 0" class="project">
                <div (contextmenu)="addComment(quarter, project, $event)"
                     *ngFor="let quarter of quarters"
                     [attr.quarter]="quarter.time"
                     [attr.workproject]="project.workproject_id"
                     [class.full]="quarterIsWholeHour(quarter.time)"
                     [class.hascomment]="hascomment(quarter.time)"
                     [class.officetime]="officetime(quarter.time)"
                     [class.selected]="quarterSelected(project.workproject_id, quarter.time)"
                     class="quarter"
                     title="{{quarter.time | amDateFormat:'HH:mm'}}">
                </div>
            </div>
            <ng-container *ngFor="let childProject of project.children">
                <div (mouseenter)="projectHover(childProject.workproject_id)" (mouseleave)="projectLeave()" *ngIf="(childProject.children || 0) == 0"
                     class="project">
                    <div (contextmenu)="addComment(quarter, project, $event)"
                         *ngFor="let quarter of quarters"
                         [attr.quarter]="quarter.time"
                         [attr.workproject]="childProject.workproject_id"
                         [class.full]="quarterIsWholeHour(quarter.time)"
                         [class.hascomment]="hascomment(quarter.time)"
                         [class.officetime]="officetime(quarter.time)"
                         [class.selected]="quarterSelected(childProject.workproject_id, quarter.time)"
                         class="quarter"
                         title="{{quarter.time | amDateFormat:'HH:mm'}}">
                    </div>
                </div>
                <ng-container (mouseenter)="projectHover(subchildProject.workproject_id)" (mouseleave)="projectLeave()"
                              *ngFor="let subchildProject of childProject.children">
                    <div class="project">
                        <div (contextmenu)="addComment(quarter, project, $event)"
                             *ngFor="let quarter of quarters"
                             [attr.quarter]="quarter.time"
                             [attr.workproject]="subchildProject.workproject_id"
                             [class.full]="quarterIsWholeHour(quarter.time)"
                             [class.hascomment]="hascomment(quarter.time)"
                             [class.officetime]="officetime(quarter.time)"
                             [class.selected]="quarterSelected(subchildProject.workproject_id, quarter.time)"
                             class="quarter"
                             title="{{quarter.time | amDateFormat:'HH:mm'}}">
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>
<div class="aspopup">
    <div *ngIf="!isToday(selectedDate)" class="alert alert-warning"><i class="fa fa-warning"></i> Selected date is not today</div>
    <div *ngIf="isWeekend(selectedDate)" class="alert alert-warning"><i class="fa fa-warning"></i> Selected date is in weekend</div>
</div>
<div class="toolbar">
    <div class="col-xs-5">
        <my-datepicker (dateModelChange)="selectedDateChanged($event)" [dateModel]="selectedDate"></my-datepicker>

        <div (click)="today()" *ngIf="!isToday(selectedDate)" class="btn btn-default">Today</div>
        <div (click)="continue()" class="btn btn-default">Continue</div>
        <div (click)="save()" class="btn btn-default">{{saveButtonText}}</div>
        <div (click)="reload()" class="btn btn-reload btn-default">{{reloadButtonText}}</div>
    </div>
    <div class="col-xs-4">
        <div #newprojects *ngIf="searchnewprojects.length" class="newprojects">
            <ng-container *ngFor="let project of searchnewprojects">
                <div
                        (click)="addVisible(project)"
                        *ngIf="(project.children || 0) == 0"
                        class="project"
                        title="{{project.company_name}} &raquo; {{project.name}}">
                    {{project.company_name}}
                    &raquo; {{project.name}}
                </div>
                <ng-container *ngFor="let childProject of project.children">
                    <div
                            (click)="addVisible(childProject)"
                            *ngIf="(childProject.children || 0) == 0"
                            class="project"
                            title="{{project.company_name}} &raquo; {{project.name}} &raquo; {{childProject.name}}">
                        {{project.company_name}}
                        &raquo; {{project.name}}
                        &raquo; {{childProject.name}}
                    </div>
                    <ng-container *ngFor="let subchildProject of childProject.children">
                        <div
                                (click)="addVisible(subchildProject)"
                                class="project"
                                title="{{project.company_name}} &raquo; {{project.name}} &raquo; {{childProject.name}} &raquo; {{subchildProject.name}}">
                            {{project.company_name}}
                            &raquo; {{project.name}}
                            &raquo; {{childProject.name}}
                            &raquo; {{subchildProject.name}}
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <input (ngModelChange)="searchNewProject($event)" [(ngModel)]="searchNewProjectText" class="form-control" placeholder="Add a project..." type="text"/>
    </div>
    <div class="col-xs-3">
        <span class="totaltime flright">Today's total time: {{totalTime()}}</span>
    </div>
</div>
<br><br>
<div class="col-xs-6">
    <b>Usage:</b>
    <ul>
        <li>
            <b>Click:</b> Simply register or unregister one quarter
        </li>
        <li>
            <b>Ctrl + Click:</b> Unregister complete block
        </li>
        <li>
            <b>Shift + Click:</b> Register from last registration untill clicked quarter
        </li>
        <li>
            <b>Continue:</b> Register from latest registration untill now
        </li>
        <li>
            <b>Right click:</b> Enter a comment
        </li>
    </ul>
</div>
<div class="col-xs-6">
    <b>Comments:</b><br>
    <div *ngFor="let comment of utils.generateArray(comments)">
        <li><b>{{comment.key}}:</b> {{comment.value}}</li>
    </div>
</div>


<div #registrationCommentModal="bs-modal" aria-hidden="true" aria-labelledby="myLargeModalLabel" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Commentaar bij uren om {{commentQuarter?.time | amDateFormat:'HH:mm'}} uur</h4>
                <button (click)="registrationCommentModal.hide()" aria-label="Close" class="close pull-right" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="clearfix"></div>
                <h5>Project: {{commentProject?.name}}</h5>
            </div>
            <div class="modal-body">

                <textarea #commenttextarea [(ngModel)]="tempComment" style="width: 100%; height: 100px;"></textarea>

            </div>

            <div class="modal-footer">
                <button (click)="setComment()" class="btn btn-primary" type="button">Save</button>
                <button (click)="registrationCommentModal.hide()" class="btn btn-secondary" type="button">Cancel</button>
            </div>
        </div>
    </div>
</div>