import {Component, OnInit} from '@angular/core';
import {FinancialService} from '../financial.service';
import {Utils} from '../utils.class';
import {ExactService} from '../exact.service';
import {ExactSalesInvoice} from '../exactsalesinvoice';
import {combineLatest} from 'rxjs';

@Component({
    selector: 'app-financial',
    templateUrl: './financial.component.html',
    styleUrls: ['./financial.component.scss']
})
export class FinancialComponent implements OnInit {

    hourlyCompanies = [];
    hourlyEmployees = [];
    yearproducts = [];
    invoices: ExactSalesInvoice[];
    showSubs = false;

    utils = Utils;

    public generalCosts = 750;

    public hasInvoice = [];
    invoicing: number;

    constructor(private financialService: FinancialService, private exactService: ExactService) {
    }


    ngOnInit() {
        this.financialService.getHourlyEmployees().then((employees) => {
            this.hourlyEmployees = employees;
            console.log(employees);
        });

        this.financialService.getPerProduct().then((yearres) => {
            this.yearproducts = yearres;
        });
        this.load();
    }

    load(updateCache = false) {
        const companies$ = this.financialService.getHourlyCompanies();
        const invoices$ = this.exactService.getInvoices(updateCache);

        combineLatest([companies$, invoices$]).subscribe(([companies, invoices]) => {
            this.hourlyCompanies = companies;
            this.invoices = invoices.data;
            this.invoices.forEach(invoice => {
                const invoiceMonth = +(new Date(invoice.date)).getMonth();
                const currentMonth = +(new Date()).getMonth();
                if (invoiceMonth == currentMonth) {
                    this.hourlyCompanies.forEach(company => {
                        if (company.exact_account_id == invoice.invoice_to) {
                            this.hasInvoice.push(company.company_id);
                        }
                    })
                }
            })
        });
    }
    invoiceMonth(companyId: number) {
        this.invoicing = companyId;
        this.exactService.invoiceMonth(companyId).then(() => {
            this.hasInvoice.push(companyId);
            this.load(true);
            this.invoicing = null;
        }, () => {
            this.invoicing = null;
        });
    }

    calq = function (item, property) {
        return item.hourprod.price * item[property];
    };

    calqemployee = function (item, property) {
        return item.hourly_rate * item[property];
    };
}
