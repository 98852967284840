import {Component, forwardRef, Inject, OnInit} from '@angular/core';
import {AuthService} from '../auth.service'
import {ApiService} from '../api.service'
import {Router} from '@angular/router'
import {AppComponent} from "../app.component";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public message: string;
    userCredentials = {email: '', password: ''};

    constructor(private authService: AuthService,
                private apiService: ApiService,
                private router: Router,
                @Inject(forwardRef(() => AppComponent)) public app: AppComponent) {
    }

    ngOnInit() {
    }

    ionViewDidLoad() {
        console.log('ionViewDidLoad LoginPage');
    }

    public login() {
        this.message = "Aan het inloggen...";
        this.authService.getUserToken(this.userCredentials).then(response => {
            this.message = "Je bent ingelogd!";
            localStorage.setItem('accessToken', response['access_token']);
            this.apiService.createAuthorizationHeaders(response['access_token']);
            this.app.loadUserInfo();
            setTimeout(() => {
                this.router.navigate(['registration']);
            }, 200);
        }, error => {
            this.message = "Je inloggegevens zijn niet juist";
        });
    }

    public getUserInfo() {
        this.authService.getUserInfo().then(response => {
            console.log(response);
        });
    }

}
