import {Component, OnInit} from '@angular/core';
import {CompanyService} from '../company.service'
import {Company} from '../company';
import {Router} from "@angular/router";

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

    public companies: Company[];
    public filteredCompanies: Company[];
    public filter: string;

    constructor(private companyService: CompanyService,
                private router: Router) {
    }

    public doFilter() {
        if (this.filter.length < 2) {
            this.filteredCompanies = this.companies;
        } else {
            this.filteredCompanies = this.companies.filter(company => company['name'].toLowerCase().indexOf(this.filter.toLowerCase()) !== -1);
        }
    }

    ngOnInit() {
        this.companyService.getAll().then(response => {
            this.filteredCompanies = this.companies = response;
        });


    }

    private edit(companyId: number) {
        this.router.navigate(['companies', companyId]);
    }

}
