import {Injectable} from '@angular/core';
import {Registrion} from './registration'
import {ApiService} from './api.service';

@Injectable()
export class ReportService {

  constructor(private apiService : ApiService) { }

  public getRegistrations(userId, companyId: number, groupBy: string, selectedYear: number, selectedMonth: number) : Promise<Registrion[]> {
    return this.apiService.getCall<Registrion[]>('registration/report',
    {
      userId : userId,
      companyId: companyId,
      groupBy: groupBy,
      year: selectedYear,
      month: selectedMonth
    });
  }
  public getAssignmentReport(userId, selectedYear : number) : Promise<any[]> {
    return this.apiService.getCall<any[]>('users/assignmentReport',
    {
      userId : userId,
      year: selectedYear
    });
  }



}
