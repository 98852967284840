import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../project.service'
import { Project } from '../project';
import { Router } from '@angular/router'

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

    public projects: Project[];

    constructor(
        private projectService: ProjectService,
        private router: Router) { }

    ngOnInit() {
        this.projectService.getAll().then((data) => {
            this.projects = data;
            console.log(this.projects);
        });
    }

    private edit(project:Project){
        console.log(project);
        
        this.router.navigate(['/project-edit', project['workproject_id']]);

    }

}
