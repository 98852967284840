import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subject} from 'rxjs/Rx';
import 'rxjs/add/operator/toPromise';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Settings} from './settings';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';


export interface ApiResponse<T> {
    success: boolean;
    message: string;
    data: T;
}

export interface ErrorData {
    error: string;
    errormessage: string;
    data: any;
}


export class ApiErrorResponse implements ApiResponse<ErrorData> {
    data: ErrorData;
    message: string;
    success: boolean;
    status: number;
}

@Injectable()
export class ApiService {

    response: Subject<Object> = new Subject();
    response$: Observable<Object>;
    settings = Settings;
    private headers: HttpHeaders;

    constructor(private http: HttpClient,
                private router: Router) {
        this.response$ = this.response.asObservable();

        this.createAuthorizationHeaders();
    }


    public createAuthorizationHeaders(overrideToken: string = null) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.append("Accept", "application/json");
        let token = overrideToken ? overrideToken : localStorage.getItem('accessToken');
        this.headers = this.headers.append('Authorization', 'Bearer ' + token);
    }


    public getCall<T>(apiUrl: string, params: Object = {}): Promise<T> {

        const URLParams = this.buildURLParams(params);

        Settings.isLoading++;

        return this.http.get(`${Settings.API_ENDPOINT}${apiUrl}`, {params: URLParams, headers: this.headers})
            .toPromise()
            .then(response => {
                this.response.next(response);
                Settings.isLoading--;
                return response['data'] as T;
            })
            .catch(this.handleError.bind(this));
    }
    public getCall$<T>(apiUrl: string, params = {}): Observable<ApiResponse<T>> {

        params = this.buildURLParams(params);

        return this.http.get(
            `${Settings.API_ENDPOINT}${apiUrl}`, {params})
            .pipe(map(response => {
                this.response.next(response);
                return response as ApiResponse<T>;
            }), catchError(error => {
                return throwError(this.onError(error));
            }));
    }


    public postCall<T>(apiUrl: string, params: Object = {}): Promise<T> {

        Settings.isLoading++;

        return this.http.post(`${Settings.API_ENDPOINT}${apiUrl}`, params, {headers: this.headers})
            .toPromise()
            .then(response => {
                this.response.next(response);
                Settings.isLoading--;
                return response as T;
            })
            .catch(this.handleError.bind(this));
    }


    public patchCall<T>(apiUrl: string, params: Object = {}): Promise<T> {

        Settings.isLoading++;

        return this.http.patch(`${Settings.API_ENDPOINT}${apiUrl}`, params, {headers: this.headers})
            .toPromise()
            .then(response => {
                this.response.next(response);
                Settings.isLoading--;
                return response as T;
            })
            .catch(this.handleError.bind(this));
    }

    public putCall<T>(apiUrl: string, params: Object = {}): Promise<T> {

        Settings.isLoading++;

        return this.http.put(`${Settings.API_ENDPOINT}${apiUrl}`, params, {headers: this.headers})
            .toPromise()
            .then(response => {
                this.response.next(response);
                Settings.isLoading--;
                return response as T;
            })
            .catch(this.handleError.bind(this));
    }

    public deleteCall<T>(apiUrl: string, params: Object = {}): Promise<T> {

        const URLParams = this.buildURLParams(params);
        Settings.isLoading++;

        return this.http.delete(`${Settings.API_ENDPOINT}${apiUrl}`, {params: URLParams, headers: this.headers})
            .toPromise()
            .then(response => {
                this.response.next(response);
                Settings.isLoading--;
                return response as T;
            })
            .catch(this.handleError.bind(this));
    }

    private buildURLParams(data: Object): HttpParams {
        let params = new HttpParams();
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                params = params.set(key, data[key]);
            }
        }
        return params;
    }

    private handleError(error: any): Promise<any> {

        Settings.isLoading--;

        console.log('An error occured', error);

        const response = {
            success: false,
            message: '',
            data: {},
            error: error
        } as ApiResponse<any>;

        if (error.status === 0) {
            response.data['errormessage'] = 'No response from server';
        } else {
            response.data['errormessage'] = error.message;
        }

        if(error.status == 401){
            if(this.router.url != "/login"){
                console.log("Got 401, redirect to login");
                this.router.navigate(['login']);
            }
        }
        console.log('An error occured', error);
        return Promise.reject(error.message || error);


        return Promise.reject(response);
    }

    private onError(error: any): ApiErrorResponse {
        const errorApiResponse = new ApiErrorResponse();
        if (typeof error['error'] !== 'undefined') {
            if (typeof error['error']['data'] !== 'undefined') {
                errorApiResponse.data = error['error']['data'];
            }
            if (typeof error['error']['message'] !== 'undefined') {
                errorApiResponse.message = error['error']['message'];
            }
        } else {
            if (typeof error === 'string') {
                try {
                    error = JSON.parse(error);
                } catch (e) {

                }
                errorApiResponse.data = error;
            } else {
                errorApiResponse.data = new class implements ErrorData {
                    data = error;
                    error: string;
                    errormessage: string;
                };
            }
            errorApiResponse.message = 'Some server error';

        }
        errorApiResponse.success = false;
        errorApiResponse.status = error.status;

        return errorApiResponse;
    }
}
