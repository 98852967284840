<div *ngIf="project">
    <div class="form-horizontal">
        <h1>Edit project {{project?.name}}</h1>
        <div class="form-group">
            <label class="control-label col-xs-4">Company</label>
            <div class="col-xs-8">
                <select name="company" class="form-control" [(ngModel)]="project.company_id" [attr.readonly]="project.workproject_id">
                    <option *ngFor="let company of companies" [ngValue]="company.company_id">{{company.name}}</option>
                </select>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-xs-4">Parent project</label>
            <div class="col-xs-8">
                <select name="company" class="form-control" [(ngModel)]="project.parent_workproject_id" [attr.readonly]="project.workproject_id">
                    <option value="">No parent</option>
                    <optgroup *ngFor="let company of projectsbycompany" [label]="company.name" [hidden]="company.company_id != project.company_id">
                        <option *ngFor="let project of company.projects" [ngValue]="project.workproject_id">
                            {{project.name}}
                        </option>
                        <ng-container *ngFor="let project of company.projects">
                            <option *ngFor="let subproject of project.children" [ngValue]="subproject.workproject_id">
                                {{project.name}} &raquo; {{subproject.name}}
                            </option>
                        </ng-container>
                    </optgroup>
                </select>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-xs-4">Name</label>
            <div class="col-xs-8">
                <input type="text" [(ngModel)]="project.name" placeholder="name" class="form-control" />
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-xs-4">Budget</label>
            <div class="col-xs-8">
                <input type="text" [(ngModel)]="project.budget" placeholder="budget" class="form-control" />
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-xs-4">Budget fixed</label>
            <div class="col-xs-8">
                <input type="checkbox" [(ngModel)]="project.budget_fixed" />
            </div>
        </div>
        <button class="btn btn-primary" (click)="save()">Save</button>
    </div>
</div>
