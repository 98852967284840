<h1>Projects</h1>
<a [routerLink]="['/project-edit', 'new']">Add project</a>
<table class="table table-striped">
<ng-container *ngFor="let project of projects">
      <tr 
          *ngIf="(project.children || 0) == 0" 
          class="project" 
          (click)="edit(project)"
          title="{{project.company_name}} &raquo; {{project.name}}">
          <td>
          {{project.company_name}}
          &raquo; {{project.name}}
            </td>
        </tr>
      <ng-container *ngFor="let childProject of project.children">
          <tr
              *ngIf="(childProject.children || 0) == 0" 
              (click)="edit(childProject)"
              class="project" 
              title="{{project.company_name}} &raquo; {{project.name}} &raquo; {{childProject.name}}">
              <td>
                    {{project.company_name}}
              &raquo; {{project.name}}
              &raquo; {{childProject.name}}
            </td>
        </tr>
          <ng-container *ngFor="let subchildProject of childProject.children">
              <tr 
                  class="project" 
                  (click)="edit(subchildProject)"
                  title="{{project.company_name}} &raquo; {{project.name}} &raquo; {{childProject.name}} &raquo; {{subchildProject.name}}">
                  <td>
                        {{project.company_name}}
                  &raquo; {{project.name}}
                  &raquo; {{childProject.name}}
                  &raquo; {{subchildProject.name}}
                  </td>
          </tr>
          </ng-container>
      </ng-container>
    
    </ng-container>
</table>