<div class="container">
    <h1>Subscriptions</h1>
    <table class="table table-striped">
        <thead class="txtbold">
        <tr>
            <td>Company</td>
            <td class="txtright">Amount</td>
            <td class="txtright">Price</td>
            <td class="txtright">Total</td>
            <td class="txtright">Item</td>
            <td class="txtright">Last date</td>
            <td class="txtright">Repeat</td>
        </tr>
        </thead>
        <tbody>

        <ng-container *ngFor="let subscription of subscriptions">
            <tr routerLink="/subscriptions/{{subscription.id}}">
                <td>{{subscription.company?.name}}</td>
                <td class="txtright">
                    <i *ngIf="subscription.count_url?.length > 0">O: </i>
                    {{subscription.count}}
                </td>
                <td class="txtright">
                    {{(items | byId : subscription.exact_item_id)?.StandardSalesPrice | currency : 'EUR'}}
                </td>
                <td class="txtright">
                    {{(subscription.count * (items | byId : subscription.exact_item_id)?.StandardSalesPrice) | currency : 'EUR'}}
                </td>
                <td class="txtright">
                    {{(subscription.count * subscription.product?.price) | currency : 'EUR'}}
                </td>
                <td *ngIf="!subscription.exact_item_id" style="color: #CCC;">
                    {{subscription.product?.name}}
                    {{subscription.product?.price | currency : 'EUR'}}
                </td>
                <td *ngIf="subscription.exact_item_id">
                    {{(items | byId : subscription.exact_item_id)?.Code}}
                    - {{subscription.description ?? (items | byId : subscription.exact_item_id)?.description}}
                </td>
                <td class="txtright">{{subscription.lastdate | date : 'dd MMM yyyy'}}</td>
                <td class="txtright">{{subscription.repeat}}</td>
            </tr>
        </ng-container>
        <tr class="txtbold">
            <td colspan="2">Totals</td>
            <td class="txtright">&euro; {{utils.sumProps(subscriptions, 'price')}}</td>
        </tr>

        </tbody>
    </table>

</div>
