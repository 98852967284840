import {Component, OnInit} from '@angular/core';
import {CompanyService} from '../company.service'
import {ActivatedRoute} from '@angular/router';
import {Company} from '../company';
import {Azureteam} from '../azureteam';
import {ExactAccount} from '../account';
import {ExactService} from '../exact.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-company-edit',
    templateUrl: './company-edit.component.html',
    styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent implements OnInit {


    public company: Company;
    public azureTeams: Azureteam[];
    public azureItems: any[];
    accounts: ExactAccount[];
    items: ExactAccount[];
    form: FormGroup;

    constructor(private companyService: CompanyService,
                private exactService: ExactService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.reload(params['companyId']);
        });
        this.exactService.getAccounts().subscribe(acc => {
            console.log(acc);
            this.accounts = acc.data;
        });
    }

    reload(companyId: any) {
        this.companyService.getSingle(companyId).then(company => {
            console.log(company);
            this.company = company;
            this.form = new FormGroup({
                company_id: new FormControl(company.company_id),
                exact_account_id: new FormControl(company.exact_account_id)
            });
        });
    }

    save() {
        this.companyService.save(this.form.value);
    }

    loadAzureTeams() {
        this.companyService.getAzureTeams().then((azureTeams) => {
            this.azureTeams = azureTeams.value;
        });
    }

    loadAzureItems() {
        this.companyService.getAzureItems(this.company.company_id).then((items) => {
            console.log(items);
            this.azureItems = items;
        });
    }

    connectAzure(team: Azureteam) {
        this.company.azure_desc = team.name + ' / ' + team.projectName;
        this.company.azure_projectid = team.projectId;
        this.company.azure_teamid = team.id;

        this.companyService.update(this.company).then(() => {
            this.reload(this.company.company_id);
        });
    }
}
