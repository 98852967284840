import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {RegistrationService} from '../registration.service';
import {ProjectService} from '../project.service';
import {Registrion} from '../registration';
import {Project} from '../project';
import {Utils} from '../utils.class';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
    queries: {
        registrationhours: new ViewChild('registrationhours')
    }
})
export class RegistrationComponent implements OnInit {

    utils = Utils;
    public currentTime = new Date();
    public startTime = moment().startOf('day').toDate();

    @ViewChild('registrationCommentModal') modal: ModalDirective;
    @ViewChild('commenttextarea') commentTextarea;
    @ViewChild('registrationhours') registrationhours;
    public projects = null;
    public shit;
    public registrations: Registrion[] = [];
    public searchnewprojects: Project[] = [];
    public searchNewProjectText = "";
    public selectedDate: Date = new Date();
    public hours = [];
    public quarters = [];
    public commentProject: Project;
    public commentQuarter: any;
    public comments = {};
    public tempComment = "";
    public saveButtonText = "Save";
    public reloadButtonText = "Reload";
    public lastMovedQuarter = null;
    public currentChangingProjectId = null;
    public currentRegistering = null;
    public isWorking = false;
    public hoveredProject: string = null;

    constructor(
        private registrationService: RegistrationService,
        private projectService: ProjectService,
        private el: ElementRef,
        private ref: ChangeDetectorRef) {
    }

    ngOnInit() {

        function updateCurrentTime(context) {
            context.currentTime = new Date();

            // Calculate the offset so that current time + 1,3 hour is visible on screen
            let currentTimePosition = moment(context.currentTime).diff(context.startTime, 'minutes');
            let registrationBlockWidth = context.registrationhours.nativeElement.clientWidth;

            if (currentTimePosition < 60 * 17 && currentTimePosition > 60 * 8) {
                currentTimePosition = 60 * 17;
            }

            let calculatedOffset = (currentTimePosition * 1.266666666) - registrationBlockWidth + 100;

            // Set the scrollposition
            console.log("scroppling", calculatedOffset);
            context.registrationhours.nativeElement.scrollLeft = calculatedOffset;
        }

        setInterval(() => {
            updateCurrentTime(this);
        }, 60000);

        setTimeout(() => {
            updateCurrentTime(this);
        });
        let selectedDate = localStorage.getItem('selected_date');
        if (selectedDate) {
            this.selectedDateChanged(moment(selectedDate, "YYYY-MM-DD").toDate());
        } else {
            this.loadRegistrations();
        }
    }

    public projectHover(projectId: string) {
        this.hoveredProject = projectId;
    }

    public projectLeave() {
        this.hoveredProject = null;

    }

    public loadRegistrations() {
        this.quarters = [];
        this.hours = [];
        for (let i: number = 0; i < 24 * 4; i++) {
            this.quarters.push({
                time: moment(this.selectedDate).startOf('day').add(i * 15, 'minutes').toISOString()
            });
            if (i % 4 == 0)
                this.hours.push(moment(this.selectedDate).startOf('day').add(i * 15, 'minutes'));
        }

        this.projects = [];
        this.registrations = [];
        this.projectService.getVisible(this.selectedDate).then((data) => {
            this.projects = data;
            this.reloadButtonText = "Reload";
        });

        this.registrationService.getAll(this.selectedDate).then(response => {
            this.registrations = response['registrations'] || [];
            console.log("RESETCOM", response);
            this.comments = response['comments'] || {};
            if (Array.isArray(this.comments)) {
                this.comments = {};
            }
        });
    }

    reload() {
        this.reloadButtonText = "Loading...";
        this.projectService.azure().then(() => {
            this.loadRegistrations();
        }, () => {
            this.loadRegistrations();
        });
    }

    selectedDateChanged(event) {
        this.selectedDate = event;
        this.selectedDate.setUTCHours(10);
        if (moment(this.selectedDate).isSame(moment(), 'day')) {
            console.log("resetting storage cuase tutoda");
            localStorage.removeItem('selected_date');
        } else {
            console.log("SETR");
            localStorage.setItem('selected_date', moment(event).format("YYYY-MM-DD"));
        }
        this.loadRegistrations();
    }

    public save() {
        console.log(this.comments);
        this.saveButtonText = "saving...";
        this.registrationService.save(this.selectedDate, this.registrations, this.comments).then((data) => {
            this.saveButtonText = "saved!";
            setTimeout(() => this.saveButtonText = "save changes", 1500);
        });
    }

    public continue() {
        let latestOther: Registrion = null;
        this.registrations.forEach(registration => {
            if (moment(registration.till).isBefore(moment())) {
                if (latestOther) {
                    // But when latestother is set, the till should also be later then current latestOther
                    if (moment(latestOther.till).isBefore(registration.till)) {
                        latestOther = registration;
                    }
                } else {
                    latestOther = registration;
                }
            }
        });

        if (latestOther) {
            console.log("Have latestohter", latestOther);
            let remainder = moment().minute() % 15 * -1;
            if (remainder >= -7) {
                remainder = remainder - 15;
            }
            let nearest = moment().add(remainder, 'minutes').set('seconds', 0).set('millisecond', 0).toDate();
            console.log(nearest);

            let event = new MouseEvent('mousemove', {shiftKey: true});

            this.register(nearest, latestOther.workproject_id, event);
        }
    };

    public addComment(quarter: any, project: any, event: MouseEvent) {
        this.commentProject = project;
        this.commentQuarter = quarter;
        this.modal.show();
        let indexTime = (new Date(this.commentQuarter.time)).toString().substr(16, 5);
        this.tempComment = this.comments[indexTime] || "";
        setTimeout(() => {
            this.commentTextarea.nativeElement.focus();
        }, 500);
        return false;
    }

    public totalTime(projectId: number = null) {
        let time = 0;
        let projectRegistrations = projectId ? this.registrations.filter(p => p.workproject_id == projectId) : this.registrations;

        projectRegistrations.forEach((reg) => {
            time += (new Date(reg.till).getTime() - new Date(reg.from).getTime());
        });
        if (time > 0) {
            let minutes = time / 1000 / 60;
            return Math.floor(minutes / 60) + ":" + ("0" + (minutes % 60)).substr(-2);
        }
        return "";
    }

    public lg(var2) {

        let quarter = new Date(var2.target.getAttribute('quarter'));
        let workproject = var2.target.getAttribute('workproject');
        if (var2.target.className.indexOf("quarter") == -1) {
            return false;
        }
        if (var2.type == "mousedown") {
            this.currentChangingProjectId = +workproject;
            this.currentRegistering = !this.quarterSelected(this.currentChangingProjectId, quarter);
        }
        if (var2.type == "mouseup") {
            this.lastMovedQuarter = null;
            this.currentChangingProjectId = null;
            this.currentRegistering = null;
        } else if (var2.buttons == 1 || var2.type == 'click') {
            if (this.lastMovedQuarter != quarter || var2.type == 'click') {
                this.lastMovedQuarter = quarter;
                this.register(quarter, this.currentChangingProjectId || workproject, var2);
            }
        }
    }


    public setComment() {
        let indexTime = (new Date(this.commentQuarter.time)).toString().substr(16, 5);
        this.comments[indexTime] = this.tempComment;
        this.modal.hide();
    }

    public register(quarter: any, project: any, event: MouseEvent) {
        if (this.isWorking) {
            console.log("SIWORK", event.type);
            return;
        }
        this.isWorking = true;

        quarter = new Date(quarter);
        project = +project;

        let newRegistration = new Registrion();
        newRegistration.workproject_id = project;
        newRegistration.from = quarter;
        newRegistration.till = moment(quarter).add(15, 'minutes').toDate();
        var impossible = false;

        // Loop through registrations and check wheter there is already work registered this time
        this.registrations.forEach(registration => {
            // Is this registration same or around (starting before, ending after) new quarter?
            if (moment(registration.from).isSameOrBefore(newRegistration.from)
                && moment(registration.till).isSameOrAfter(newRegistration.till)) {

                // If same project, unregister
                if (this.currentRegistering === false && newRegistration.workproject_id == registration.workproject_id) {
                    if (moment(registration.from).isSame(newRegistration.from)) {
                        if (moment(registration.till).isSame(newRegistration.till)) {
                            // from and till both same, simple splice
                            this.registrations.splice(this.registrations.indexOf(registration), 1);
                        } else {
                            // From equal, till different
                            // Start the registration from the end of the clicked quarter
                            registration.from = newRegistration.till;
                        }
                    }
                    // From not equal, but till is equal?
                    else if (moment(registration.till).isSame(newRegistration.till)) {
                        // Then end registration on the from moment of clicked quarter
                        registration.till = newRegistration.from;
                    } else {
                        // There is a registration around, but start or end is not matching clicked quarter

                        // If ctrlKey down, we have to remove the complete registration
                        if (event.ctrlKey) {
                            this.registrations.splice(this.registrations.indexOf(registration), 1);
                        } else {
                            // Else we have to splice the registration in 2 registrations
                            let extraReg = new Registrion();
                            extraReg.workproject_id = newRegistration.workproject_id;
                            extraReg.from = newRegistration.till;
                            extraReg.till = registration.till;
                            this.registrations.push(extraReg);

                            registration.till = newRegistration.from;
                        }
                    }
                }

                // Never register new times
                impossible = true;
                //console.log("impossible", moment(registration.till), moment(newRegistration.till));
            }
        });

        if (this.currentRegistering === false) {
            impossible = true;
        }

        // When a quarter was clicked which have already a registration
        // We never register new times, so return
        if (impossible) {
            this.isWorking = false;
            return false;
        }

        var extendedOther = false;

        // When shiftKey is pressed, we should extend the latest project
        if (event.shiftKey) {
            var latestOther = null;
            this.registrations.forEach(registration => {
                // When till is before the start of new registration, we have a potential latestOther
                if (moment(registration.till).isSameOrBefore(newRegistration.from)) {
                    if (latestOther) {
                        // But when latestother is set, the till should also be later then current latestOther
                        if (moment(latestOther.till).isSameOrBefore(registration.till)) {
                            latestOther = registration;
                        }
                    } else {
                        latestOther = registration;
                    }
                }
            });
            if (latestOther && latestOther.workproject_id == newRegistration.workproject_id) {
                latestOther.till = newRegistration.till;
                extendedOther = true;
            }
        } else {
            // Check wheter we could extend a current excisting registration
            this.registrations.forEach(registration => {
                if (!extendedOther && registration.workproject_id == project) {
                    if (moment(registration.from).isSame(newRegistration.till)) {
                        registration.from = newRegistration.from;
                        extendedOther = true;
                    }
                    if (moment(registration.till).isSame(newRegistration.from)) {
                        registration.till = newRegistration.till;
                        extendedOther = true;
                    }
                    if (event.type == 'mousemove' &&
                        moment(registration.till).isBefore(newRegistration.from) &&
                        moment(newRegistration.from).diff(registration.till, 'minutes') <= 30
                    ) {
                        registration.till = newRegistration.till;
                        extendedOther = true;
                    }
                }
            });
        }

        // When we didn't extend another registration, push the new registration object
        if (!extendedOther)
            this.registrations.push(newRegistration);

        console.log("newRegistration", newRegistration);

        // Check wheter we could simplify the registration
        // This is the case when we register subsequent registrations for same projects
        var lastRegistration = null;
        this.registrations
            .sort((a, b) => {
                return moment(a.from).diff(b.from);
            })
            .forEach(registration => {

                if (lastRegistration
                    && moment(lastRegistration.till).isSame(registration.from)
                    && lastRegistration.workproject_id == registration.workproject_id) {
                    lastRegistration.till = registration.till;
                    this.registrations.splice(this.registrations.indexOf(registration), 1);
                }

                lastRegistration = registration;
            });

        console.log(this.registrations);
        this.ref.detectChanges();

        this.isWorking = false;
    }

    public quarterIsWholeHour(quarter) {
        return new Date(quarter).getMinutes() === 0;
    }

    public officetime(quarter) {
        let hour = new Date(quarter).getHours();
        return hour > 7 && hour < 17;
    }

    public hascomment(time) {
        let indexTime = (new Date(time)).toString().substr(16, 5);
        return this.comments.hasOwnProperty(indexTime);
    }

    public isToday(date: Date) {
        return moment(date).isSame(new Date(), 'day');
    }

    public isWeekend(date: Date) {
        return [6, 7].indexOf(moment(date).isoWeekday()) != -1;
    }

    public today() {
        this.selectedDateChanged(new Date());
    }

    public removeVisible(project: Project) {
        this.searchnewprojects = [];
        this.searchNewProjectText = "";
        this.projectService.toggleVisible(project, false).then((data) => {
            this.loadRegistrations();
        });
    }

    public addVisible(project: Project) {
        this.searchnewprojects = [];
        this.searchNewProjectText = "";
        this.projectService.toggleVisible(project, true).then((data) => {
            this.loadRegistrations();
        });
    }

    public searchNewProject(searchText) {
        if (searchText.length > 2) {
            this.searchnewprojects = [];
            this.projectService.findHiddenProject(searchText, this.selectedDate).then((data) => {
                if (data.length > 0)
                    this.searchnewprojects = <Project[]>data;

            });
        }
    }

    private quarterSelected(project, quarter) {
        let quarterTime = new Date(quarter).getTime();

        return this.registrations
                   .filter(p => p.workproject_id === project)
                   .filter(registration => (
                       new Date(registration.from).getTime() <= quarterTime &&
                       new Date(registration.till).getTime() > quarterTime
                   )).length > 0;
    }
}
