<div class="container">
    <h1>Subscription #{{subscription.id}} - {{subscription.company?.name}}</h1>
    <h3>{{subscription.product?.name}}</h3>
    <h4>{{subscription.product?.price | currency : 'EUR'}}</h4>

    <ng-container *ngIf="form">
        Description on invoice (empty = product name):
        <input [formControl]="form.controls['description']" type="text"><br>
        Internal comment:
        <input [formControl]="form.controls['comment']" type="text"><br>
        Count URL:
        <input [formControl]="form.controls['count_url']" type="text"><br>
        Count:
        <input [formControl]="form.controls['count']" type="text"><br>
        <ng-select [items]="items"
                   bindValue="id"
                   [formControl]="form.controls['exact_item_id']">
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
                {{item.Code}} - {{item.description}} - {{item.StandardSalesPrice | currency : 'EUR'}}
            </ng-template>
        </ng-select>
        <button (click)="save()">Opslaan</button>
    </ng-container>

    <pre>

    {{subscription | json}}
    </pre>
</div>
