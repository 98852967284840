import {Component, forwardRef, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../user.service';
import {CompanyService} from '../company.service';
import {ReportService} from '../report.service';
import {ProjectService} from '../project.service';
import {User} from '../user';
import {Project} from '../project';
import {Company} from '../company';
import {Utils} from '../utils.class';
import * as moment from 'moment';
import {AppComponent} from '../app.component';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnDestroy {
    companyId: number;
    userId: number;
    utils: Utils = Utils;
    private sub: any;

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    public users: User[];
    public projects: Project[];
    public projectById: any[] = [];
    public userById: {} = {};
    public companies: Company[];
    public registrations: any[];
    public assignments: any[];
    public totals: any[];
    public plaintotals: any[];
    public totalsbudget = [];
    public comments: any[];
    public years: number[] = [];
    public selectedYear = moment().toDate().getFullYear();
    public selectedMonth = moment().toDate().getMonth() + 1;


    constructor(private userService: UserService,
                private companyService: CompanyService,
                private projectService: ProjectService,
                private route: ActivatedRoute,
                private reportService: ReportService,
                @Inject(forwardRef(() => AppComponent)) public app: AppComponent) {
    }

    ngOnInit() {
        for (let year = 2015; year <= moment().toDate().getFullYear(); year++) {
            this.years.push(year);
        }

        this.sub = this.route.params.subscribe(params => {
            console.log(params);
            this.companyId = +params['companyId']; // (+) converts string 'id' to a number
            this.userId = +params['userId']; // (+) converts string 'id' to a number

            if (this.projectById.length > 0)
                this.loadRegistrations();

        });

        this.projectService.getAll().then((data) => {
            let projbids = [];
            this.projects = data;
            this.loadUsers();

            data.forEach((project) => {
                projbids[project['workproject_id']] = project;
                project['children'].forEach((childProject) => {
                    childProject['parent_name'] = project['name'];
                    childProject['company_name'] = project['company_name'];
                    projbids[childProject['workproject_id']] = childProject;
                    if (childProject['children']) {
                        childProject['children'].forEach((subChildProject) => {
                            subChildProject['parent_name'] = childProject['name'];
                            subChildProject['parent_parent_name'] = project['name'];
                            subChildProject['company_name'] = project['company_name'];
                            projbids[subChildProject['workproject_id']] = subChildProject;
                        });
                    }
                });
            });
            this.projectById = projbids;

        });

    }

    public loadUsers() {

        this.userService.getAll().then((users) => {
            this.users = users;
            this.users.forEach((user) => {
                this.userById[user['user_id']] = user['name'];
            });
            console.log(this.userById);

            this.loadCompanies();
        });

    }

    getCompany(companyId) {
        if (!this.companies)
            return {};
        return this.companies.filter(c => c.company_id == companyId)[0];
    }

    private loadCompanies() {
        this.companyService.getHavingRegistrations().then((companies) => {
            this.companies = companies;

            this.loadRegistrations();
        });
    }

    generateArray(obj) {
        if (!obj) {
            return [];
        }
        return Object.keys(obj).map((key) => {
            return {key: key, value: obj[key]}
        });
    }

    public loadRegistrations() {

        this.registrations = [];

        if (this.companyId || this.userId) {
            this.reportService.getRegistrations(this.userId, this.companyId, null, this.selectedYear, this.selectedMonth).then((data) => {


                let registrations = data['registrations'];
                let comments = data['comments'];
                this.comments = comments;

                registrations.forEach((registration) => {
                    let date = moment(registration.from).format("YYYYMMDD");

                    registration.comments = this.comments
                        .filter(p => p.user_id == registration.user_id)
                        .filter(comment => (
                            new Date(comment.datetime).getTime() >= new Date(registration.from).getTime() &&
                            new Date(comment.datetime).getTime() < new Date(registration.till).getTime()
                        ))
                        .sort((a, b) => {
                            return ((+new Date(a.datetime)) - (+new Date(b.datetime)));
                        });

                    if (!this.registrations[date]) {
                        this.registrations[date] = {};
                    }
                    if (!this.registrations[date][registration.user_id]) {
                        this.registrations[date][registration.user_id] = [];
                    }
                    this.registrations[date][registration.user_id].push(registration);
                });
            });
            this.reportService.getRegistrations(this.userId, this.companyId, 'project', this.selectedYear, this.selectedMonth).then((data) => {
                console.log(data);
                let totals = data['registrations'];
                let newTotals = {};
                let curpar = null;
                totals.forEach((total) => {
                    if(!newTotals[total['parent_workproject_id']]){
                        newTotals[total['parent_workproject_id']] = [];
                    }
                    newTotals[total['parent_workproject_id']].push(total);
                    console.log(newTotals[total['parent_workproject_id']]);
                });
                console.log(newTotals);

                let keys = Object.keys(newTotals);

                let goodTotals = [];

                for(let dakey of keys){
                    goodTotals.push({
                        workproject_id: dakey,
                        childs: newTotals[dakey]
                    });
                }
                console.log(goodTotals);

                this.totals = goodTotals;
                this.totalsbudget = new Array();
                totals.forEach(total => {
                    if (total['budget'] > 0) {
                        this.totalsbudget.push(total);
                    }
                });

                this.plaintotals = totals;
                console.log(this.totals, this.totalsbudget);
            });
        }

        if (this.userId) {
            this.reportService.getAssignmentReport(this.userId, this.selectedYear).then((data) => {
                this.assignments = data;
                console.log(this.assignments);
            });
        }
    }

}
