import { Injectable } from '@angular/core';
import {ApiService} from './api.service';

@Injectable()
export class FinancialService {

    constructor(private apiService: ApiService) { }

    public getHourlyCompanies(): Promise<Object[]> {
        return this.apiService.getCall<Object[]>('financial/hourlycompanies', null);
    }

    public getHourlyEmployees(): Promise<Object[]> {
        return this.apiService.getCall<Object[]>('financial/hourlyemployees', null);
    }

    public getPerProduct(): Promise<Object[]> {
        return this.apiService.getCall<Object[]>('financial/products', null);
    }

}
